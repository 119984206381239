import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import ObservationRecord from './ObservationRecord';
import Modal from './Modal';

const ObservationPlan = () => {
  const { studyId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(true);
  const [observationPlan, setObservationPlan] = useState([]);
  const [sampleSize, setSampleSize] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [existingObservations, setExistingObservations] = useState({});
  const [completedObservationsCount, setCompletedObservationsCount] = useState(0);
  const [studyTitle, setStudyTitle] = useState('');

  useEffect(() => {
    const fetchStudy = async () => {
      if (studyId) {
        const studyRef = doc(db, 'studies', studyId);
        const studySnap = await getDoc(studyRef);
        if (studySnap.exists()) {
          const studyData = studySnap.data();
          setValue('numDias', studyData.numDias || '');
          setValue('horaInicio', studyData.horaInicio || '');
          setValue('horaFin', studyData.horaFin || '');
          setObservationPlan(studyData.observationPlan || []);
          setSampleSize(studyData.statisticalParameters?.n || 0);
          setExistingObservations(studyData.observations || {});
          const observationsCount = Object.keys(studyData.observations || {}).length;
          setCompletedObservationsCount(observationsCount);
          setStudyTitle(studyData.idEstudio || '');
        }
      }
      setLoading(false);
    };

    fetchStudy();
  }, [studyId, setValue]);

  const generateTimeSlots = (horaInicio: Date, horaFin: Date, count: number) => {
    const timeSlots = new Set<string>();
    const totalMinutes = (horaFin.getTime() - horaInicio.getTime()) / (1000 * 60);
    
    while (timeSlots.size < count) {
      const randomMinutes = Math.floor(Math.random() * totalMinutes);
      const time = new Date(horaInicio.getTime() + randomMinutes * 60 * 1000);
      const timeString = time.toISOString();
      
      if (!timeSlots.has(timeString)) {
        timeSlots.add(timeString);
      }
    }
    
    return Array.from(timeSlots).sort();
  };

  const generateObservationPlan = (numDias: number, horaInicio: Date, horaFin: Date, tamanoMuestra: number) => {
    const observacionesRestantes = Math.max(0, tamanoMuestra - completedObservationsCount);
    const observacionesPorDia = Math.ceil(observacionesRestantes / numDias);
    const plan = [];

    for (let dia = 1; dia <= numDias; dia++) {
      const observacionesDia = generateTimeSlots(horaInicio, horaFin, observacionesPorDia);
      plan.push({ dia, observaciones: observacionesDia });
    }

    return plan;
  };

  const onSubmit = async (data) => {
    const numDias = parseInt(data.numDias);
    const horaInicio = new Date(`2000-01-01T${data.horaInicio}`);
    const horaFin = new Date(`2000-01-01T${data.horaFin}`);

    if (horaFin <= horaInicio) {
      alert("La hora de fin debe ser posterior a la hora de inicio.");
      return;
    }

    setFormData({ numDias, horaInicio, horaFin });

    if (completedObservationsCount > 0) {
      setShowConfirmModal(true);
    } else {
      generateAndSavePlan({ numDias, horaInicio, horaFin }, true);
    }
  };

  const generateAndSavePlan = async ({ numDias, horaInicio, horaFin }, isNewPlan) => {
    let plan;
    if (isNewPlan) {
      plan = generateObservationPlan(numDias, horaInicio, horaFin, sampleSize);
    } else {
      plan = observationPlan;
    }

    try {
      const studyRef = doc(db, 'studies', studyId);
      await updateDoc(studyRef, {
        numDias,
        horaInicio: horaInicio.toTimeString().slice(0, 5),
        horaFin: horaFin.toTimeString().slice(0, 5),
        observationPlan: plan,
        observations: existingObservations
      });
      setObservationPlan(plan);
      navigate(`/study/${studyId}/record`, { replace: true });
    } catch (error) {
      console.error('Error al guardar el plan de observaciones:', error);
    }
  };

  const handleConfirm = () => {
    setShowConfirmModal(false);
    if (formData) {
      generateAndSavePlan(formData, true);
    }
  };

  const handleCancel = () => {
    setShowConfirmModal(false);
    if (formData) {
      generateAndSavePlan(formData, false);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">
        Plan de Observaciones {studyTitle ? `(${studyTitle})` : ''}
      </h1>
      <p className="mb-4">Tamaño de muestra requerido: {sampleSize}</p>
      <p className="mb-4">Observaciones completadas: {completedObservationsCount}</p>
      <p className="mb-4">Observaciones restantes: {Math.max(0, sampleSize - completedObservationsCount)}</p>
      
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 mb-8">
        <div>
          <label htmlFor="numDias" className="block text-sm font-medium text-gray-700">
            Número de días
          </label>
          <input
            type="number"
            id="numDias"
            {...register('numDias', { required: true, min: 1 })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.numDias && <p className="text-red-500 text-xs italic">El número de días es requerido y debe ser mayor que 0.</p>}
        </div>

        <div>
          <label htmlFor="horaInicio" className="block text-sm font-medium text-gray-700">
            Hora de inicio de la jornada
          </label>
          <input
            type="time"
            id="horaInicio"
            {...register('horaInicio', { required: true })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.horaInicio && <p className="text-red-500 text-xs italic">La hora de inicio es requerida.</p>}
        </div>

        <div>
          <label htmlFor="horaFin" className="block text-sm font-medium text-gray-700">
            Hora de fin de la jornada
          </label>
          <input
            type="time"
            id="horaFin"
            {...register('horaFin', { required: true })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.horaFin && <p className="text-red-500 text-xs italic">La hora de fin es requerida.</p>}
        </div>

        <div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Generar Plan de Observaciones
          </button>
        </div>
      </form>

      <Modal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title="Confirmar Generación de Nuevo Plan"
      >
        <p>Ya existen observaciones registradas para este estudio. ¿Desea generar un nuevo plan de observaciones o continuar con el plan actual?</p>
        <p>Las observaciones existentes se mantendrán en ambos casos.</p>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={handleCancel}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            Continuar con Plan Actual
          </button>
          <button
            onClick={handleConfirm}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Generar Nuevo Plan
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ObservationPlan;