import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Document, HeadingLevel, ImageRun, Paragraph, Table, TableCell, TableRow, WidthType, AlignmentType, BorderStyle, ShadingType, Packer } from 'docx';
import { saveAs } from 'file-saver';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const createHeading = (text: string, level: HeadingLevel = HeadingLevel.HEADING_1) => {
  return new Paragraph({
    text,
    heading: level,
    spacing: { before: 300, after: 200 },
    alignment: AlignmentType.LEFT,
  });
};

const createTable = (rows: { key: string; value: string }[], borders?: any) => {
  return new Table({
    width: { size: 100, type: WidthType.PERCENTAGE },
    borders: borders || {
      top: { style: BorderStyle.NONE },
      bottom: { style: BorderStyle.NONE },
      left: { style: BorderStyle.NONE },
      right: { style: BorderStyle.NONE },
      insideHorizontal: { style: BorderStyle.NONE },
      insideVertical: { style: BorderStyle.NONE }
    },
    rows: rows.map(row => new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({ text: row.key, alignment: AlignmentType.LEFT })],
          width: { size: 40, type: WidthType.PERCENTAGE },
        }),
        new TableCell({
          children: [new Paragraph({ text: row.value, alignment: AlignmentType.LEFT })],
          width: { size: 60, type: WidthType.PERCENTAGE },
        }),
      ],
    })),
  });
};

const createParagraph = (text: string) => {
  return new Paragraph({
    text,
    spacing: { after: 200 },
    alignment: AlignmentType.JUSTIFIED,
  });
};

const generateDocx = (study: any, reportData: any, chartImages: any) => {
  const { pieChartBase64, barChartBase64, workstationChartImages } = chartImages;

  const generalInfoRows = [
    { key: "Empresa", value: study.empresa },
    { key: "Fecha", value: study.fecha },
    { key: "Técnico", value: study.tecnico },
    { key: "Departamento", value: study.departamento },
    { key: "ID del Estudio", value: study.idEstudio },
    { key: "Sistema de Actividad", value: study.activitySystem === 'centesimal' ? 'Centesimal (Normal: 100, Óptima: 133)' : 'Bedaux (Normal: 60, Óptima: 80)' }
  ];

  const statisticsRows = [
    { key: "Total de observaciones", value: reportData.totalObservations.toString() },
    { key: "Actividades monitoreadas", value: `${reportData.totalMonitored} (${((reportData.totalMonitored / reportData.totalObservations) * 100).toFixed(2)}%)` },
    { key: "Actividades no monitoreadas", value: `${reportData.totalNonMonitored} (${((reportData.totalNonMonitored / reportData.totalObservations) * 100).toFixed(2)}%)` },
    { key: "Número de puestos de trabajo", value: study.workstations.length.toString() },
    { key: "Promedio de observaciones por puesto", value: (reportData.totalObservations / study.workstations.length).toFixed(2) }
  ];

  const objectivesAndNotesRows = [
    { key: "Objetivos del Estudio", value: study.objetivos || 'No especificados' },
    ...(study.notas ? [{ key: "Notas Adicionales", value: study.notas }] : [])
  ];

  const statisticalParamsRows = [
    { key: "Nivel de confianza", value: `${study.statisticalParameters.confidenceLevel}%` },
    { key: "Precisión", value: `${study.statisticalParameters.precision}%` },
    { key: "Proporción estimada", value: `${study.statisticalParameters.estimatedProportion}%` },
    { key: "Observaciones preliminares", value: study.statisticalParameters.preliminaryObservations?.toString() || 'N/A' },
    { key: "Actividades de interés", value: study.statisticalParameters.activitiesOfInterest?.toString() || 'N/A' },
    { key: "Proporción (p)", value: study.statisticalParameters.p.toFixed(4) },
    { key: "Complemento (q)", value: study.statisticalParameters.q.toFixed(4) },
    { key: "Tamaño de muestra recomendado (n)", value: study.statisticalParameters.n.toString() },
    { key: "Error actual (h%)", value: `${study.statisticalParameters.h.toFixed(2)}%` }
  ];

  const invisibleBorders = {
    top: { style: BorderStyle.NONE },
    bottom: { style: BorderStyle.NONE },
    left: { style: BorderStyle.NONE },
    right: { style: BorderStyle.NONE },
    insideHorizontal: { style: BorderStyle.NONE },
    insideVertical: { style: BorderStyle.NONE }
  };

  const children = [
    createHeading("Informe Ejecutivo Detallado", HeadingLevel.HEADING_1),
    
    // Información General
    createHeading("1. Información General", HeadingLevel.HEADING_2),
    createTable(generalInfoRows, invisibleBorders),
    
    // Estadísticas Generales
    createHeading("2. Estadísticas Generales", HeadingLevel.HEADING_2),
    createTable(statisticsRows, invisibleBorders),
    
    // Objetivos y Notas
    createHeading("3. Objetivos y Notas", HeadingLevel.HEADING_2),
    createTable(objectivesAndNotesRows, invisibleBorders),
    
    // Parámetros Estadísticos
    createHeading("4. Parámetros Estadísticos", HeadingLevel.HEADING_2),
    createTable(statisticalParamsRows, invisibleBorders),
    
    // Distribución General de Actividades
    createHeading("5. Distribución General de Actividades", HeadingLevel.HEADING_2),
    new Table({
      width: { size: 100, type: WidthType.PERCENTAGE },
      borders: invisibleBorders,
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: `Total de observaciones monitoreadas: ${reportData.totalMonitored} (${((reportData.totalMonitored / reportData.totalObservations) * 100).toFixed(2)}%)`,
                  spacing: { after: 200 }
                }),
                new Paragraph({
                  text: `Total de observaciones no monitoreadas: ${reportData.totalNonMonitored} (${((reportData.totalNonMonitored / reportData.totalObservations) * 100).toFixed(2)}%)`,
                  spacing: { after: 200 }
                }),
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: pieChartBase64,
                      transformation: { width: 200, height: 200 }
                    })
                  ],
                  spacing: { after: 200 },
                  alignment: AlignmentType.CENTER
                })
              ],
              shading: { fill: "F5F5F5", type: ShadingType.CLEAR }
            })
          ]
        })
      ]
    }),
    
    // Comparación entre Puestos
    createHeading("6. Comparación entre Puestos de Trabajo", HeadingLevel.HEADING_2),
    new Table({
      width: { size: 100, type: WidthType.PERCENTAGE },
      borders: invisibleBorders,
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "Distribución de actividades monitoreadas y no monitoreadas por puesto de trabajo:",
                  spacing: { after: 200 }
                }),
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: barChartBase64,
                      transformation: { width: 590, height: 320 }
                    })
                  ],
                  spacing: { after: 200 },
                  alignment: AlignmentType.CENTER
                })
              ],
              shading: { fill: "F5F5F5", type: ShadingType.CLEAR }
            })
          ]
        })
      ]
    }),
    
    // Desglose por Puesto de Trabajo
    createHeading("7. Desglose Detallado por Puesto de Trabajo", HeadingLevel.HEADING_2),
    
    ...reportData.workstationData.flatMap((wd: any, index: number) => {
      const chartImage = workstationChartImages[index];
      const workstationNumber = index + 1;

      return [
        createHeading(`7.${workstationNumber}. ${wd.name}`, HeadingLevel.HEADING_3),
        
        new Table({
          width: { size: 100, type: WidthType.PERCENTAGE },
          borders: invisibleBorders,
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Table({
                      width: { size: 100, type: WidthType.PERCENTAGE },
                      borders: invisibleBorders,
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({ children: [new Paragraph("Total de observaciones")] }),
                            new TableCell({ children: [new Paragraph(wd.total.toString())] })
                          ]
                        }),
                        new TableRow({
                          children: [
                            new TableCell({ children: [new Paragraph("Actividades monitoreadas")] }),
                            new TableCell({ children: [new Paragraph(`${wd.monitored} (${((wd.monitored/wd.total)*100).toFixed(2)}%)`)] })
                          ]
                        }),
                        new TableRow({
                          children: [
                            new TableCell({ children: [new Paragraph("Actividades no monitoreadas")] }),
                            new TableCell({ children: [new Paragraph(`${wd.nonMonitored} (${((wd.nonMonitored/wd.total)*100).toFixed(2)}%)`)] })
                          ]
                        }),
                        new TableRow({
                          children: [
                            new TableCell({ children: [new Paragraph("Actividad media")] }),
                            new TableCell({ children: [new Paragraph(wd.averageActivity ? wd.averageActivity.toFixed(2) : 'N/A')] })
                          ]
                        }),
                        new TableRow({
                          children: [
                            new TableCell({ children: [new Paragraph("Valoración del ritmo")] }),
                            new TableCell({ children: [new Paragraph(wd.activityStatus)] })
                          ]
                        })
                      ]
                    }),
                    chartImage ? new Paragraph({
                      children: [
                        new ImageRun({
                          data: chartImage,
                          transformation: { width: 150, height: 150 }
                        })
                      ],
                      spacing: { before: 200, after: 200 },
                      alignment: AlignmentType.CENTER
                    }) : new Paragraph(""),
                    new Paragraph({ text: "Desglose de Tareas:", spacing: { before: 200, after: 200 } }),
                    new Table({
                      width: { size: 100, type: WidthType.PERCENTAGE },
                      borders: invisibleBorders,
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({ children: [new Paragraph({ text: "Tarea", bold: true })] }),
                            new TableCell({ children: [new Paragraph({ text: "Observaciones", bold: true })] }),
                            new TableCell({ children: [new Paragraph({ text: "Porcentaje", bold: true })] }),
                            new TableCell({ children: [new Paragraph({ text: "Estado", bold: true })] })
                          ]
                        }),
                        ...wd.tasks.map((task: any) => new TableRow({
                          children: [
                            new TableCell({ children: [new Paragraph(task.name)] }),
                            new TableCell({ children: [new Paragraph(wd.taskCounts[task.id].toString())] }),
                            new TableCell({ children: [new Paragraph(`${((wd.taskCounts[task.id] / wd.total) * 100).toFixed(2)}%`)] }),
                            new TableCell({ children: [new Paragraph(task.monitored ? 'Monitoreada' : 'No Monitoreada')] })
                          ]
                        }))
                      ]
                    })
                  ],
                  shading: { fill: "F5F5F5", type: ShadingType.CLEAR }
                })
              ]
            })
          ]
        }),

        // Espacio entre puestos
        new Paragraph({ text: "", spacing: { after: 400 } })
      ];
    })
  ];

  return new Document({
    sections: [{
      properties: {},
      children: children
    }]
  });
};

const ExecutiveReport: React.FC = () => {
  const { studyId } = useParams<{ studyId: string }>();
  const [study, setStudy] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const reportRef = useRef<HTMLDivElement>(null);
  const pieChartRef = useRef<ChartJS>(null);
  const barChartRef = useRef<ChartJS>(null);
  const workstationChartRefs = useRef<{ [key: string]: ChartJS | null }>({});

  useEffect(() => {
    const fetchStudy = async () => {
      if (studyId) {
        const studyRef = doc(db, 'studies', studyId);
        const studySnap = await getDoc(studyRef);
        if (studySnap.exists()) {
          setStudy(studySnap.data());
        }
      }
      setLoading(false);
    };

    fetchStudy();
  }, [studyId]);

  const calculateAverageActivity = (workstationId: string, observations: any) => {
    const workstationObservations = Object.values(observations || {}).filter((obs: any) => 
      obs[workstationId] && obs[workstationId].activity
    );

    if (workstationObservations.length === 0) return null;

    const totalActivity = workstationObservations.reduce((sum: number, obs: any) => 
      sum + obs[workstationId].activity, 0
    );

    return totalActivity / workstationObservations.length;
  };

  const getActivityStatus = (activity: number, activitySystem: 'centesimal' | 'bedaux') => {
    const normalActivity = activitySystem === 'centesimal' ? 100 : 60;
    const optimalActivity = activitySystem === 'centesimal' ? 133 : 80;
    
    if (activity < normalActivity) return 'Lenta';
    if (activity > optimalActivity) return 'Excesiva';
    if (activity === normalActivity) return 'Normal';
    if (activity > normalActivity && activity <= optimalActivity) return 'Rápida';
    return 'No definida';
  };

  const generateReport = () => {
    if (!study) return null;

    let totalObservations = 0;
    let totalMonitored = 0;
    let totalNonMonitored = 0;

    const workstationData = study.workstations.map((workstation: any, index: number) => {
      const workstationObservations = Object.values(study.observations || {}).filter((obs: any) => 
        Object.keys(obs).includes(workstation.id)
      );

      const taskCounts = workstation.tasks.reduce((acc: any, task: any) => {
        acc[task.id] = 0;
        return acc;
      }, {});

      workstationObservations.forEach((obs: any) => {
        const taskId = obs[workstation.id].taskId;
        if (taskCounts.hasOwnProperty(taskId)) {
          taskCounts[taskId]++;
        }
      });

      const monitoredCount = workstationObservations.filter((obs: any) => {
        const task = workstation.tasks.find((t: any) => t.id === obs[workstation.id].taskId);
        return task && task.monitored;
      }).length;

      const nonMonitoredCount = workstationObservations.length - monitoredCount;
      const averageActivity = calculateAverageActivity(workstation.id, study.observations);

      totalObservations += workstationObservations.length;
      totalMonitored += monitoredCount;
      totalNonMonitored += nonMonitoredCount;

      return {
        name: workstation.name,
        monitored: monitoredCount,
        nonMonitored: nonMonitoredCount,
        total: workstationObservations.length,
        color: `hsl(${index * 137.5}, 70%, 50%)`,
        taskCounts: taskCounts,
        tasks: workstation.tasks,
        averageActivity,
        activityStatus: averageActivity ? getActivityStatus(averageActivity, study.activitySystem) : 'No definida'
      };
    });

    const generalChartData = {
      labels: ['Monitoreadas', 'No Monitoreadas'],
      datasets: [{
        data: [totalMonitored, totalNonMonitored],
        backgroundColor: ['#36A2EB', '#FF6384'],
      }]
    };

    const workstationComparisonData = {
      labels: workstationData.map(wd => wd.name),
      datasets: [
        {
          label: 'Actividades Monitoreadas',
          data: workstationData.map(wd => wd.monitored),
          backgroundColor: '#36A2EB',
        },
        {
          label: 'Actividades No Monitoreadas',
          data: workstationData.map(wd => wd.nonMonitored),
          backgroundColor: '#FF6384',
        }
      ]
    };

    return {
      jsx: (
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold mb-6">Resumen del Estudio</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            <div className="bg-white shadow-lg rounded-lg p-6">
              <h3 className="text-xl font-bold mb-4">Información General</h3>
              <p><strong>Empresa:</strong> {study.empresa}</p>
              <p><strong>Fecha:</strong> {study.fecha}</p>
              <p><strong>Técnico:</strong> {study.tecnico}</p>
              <p><strong>Departamento:</strong> {study.departamento}</p>
              <p><strong>ID del Estudio:</strong> {study.idEstudio}</p>
              <p><strong>Sistema de Actividad:</strong> {study.activitySystem === 'centesimal' ? 'Centesimal (100-133)' : 'Bedaux (60-80)'}</p>
            </div>
            
            <div className="bg-white shadow-lg rounded-lg p-6">
              <h3 className="text-xl font-bold mb-4">Estadísticas Generales</h3>
              <p><strong>Total de observaciones:</strong> {totalObservations}</p>
              <p><strong>Actividades monitoreadas:</strong> {totalMonitored} ({((totalMonitored / totalObservations) * 100).toFixed(2)}%)</p>
              <p><strong>Actividades no monitoreadas:</strong> {totalNonMonitored} ({((totalNonMonitored / totalObservations) * 100).toFixed(2)}%)</p>
              <p><strong>Número de puestos de trabajo:</strong> {study.workstations.length}</p>
              <p><strong>Promedio de observaciones por puesto:</strong> {(totalObservations / study.workstations.length).toFixed(2)}</p>
            </div>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
            <h3 className="text-2xl font-bold mb-4">Objetivos y Notas</h3>
            <div className="mb-4">
              <h4 className="text-lg font-semibold mb-2">Objetivos del Estudio</h4>
              <p className="whitespace-pre-wrap">{study.objetivos}</p>
            </div>
            {study.notas && (
              <div>
                <h4 className="text-lg font-semibold mb-2">Notas Adicionales</h4>
                <p className="whitespace-pre-wrap">{study.notas}</p>
              </div>
            )}
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
            <h3 className="text-2xl font-bold mb-4">Parámetros Estadísticos</h3>
            <p><strong>Nivel de confianza:</strong> {study.statisticalParameters.confidenceLevel}%</p>
            <p><strong>Precisión:</strong> {study.statisticalParameters.precision}%</p>
            <p><strong>Proporción estimada:</strong> {study.statisticalParameters.estimatedProportion}%</p>
            <p><strong>Observaciones preliminares:</strong> {study.statisticalParameters.preliminaryObservations || 'N/A'}</p>
            <p><strong>Actividades de interés:</strong> {study.statisticalParameters.activitiesOfInterest || 'N/A'}</p>
            <p><strong>Proporción (p):</strong> {study.statisticalParameters.p.toFixed(4)}</p>
            <p><strong>Complemento (q):</strong> {study.statisticalParameters.q.toFixed(4)}</p>
            <p><strong>Tamaño de muestra recomendado (n):</strong> {study.statisticalParameters.n}</p>
            <p><strong>Error actual (h%):</strong> {study.statisticalParameters.h.toFixed(2)}%</p>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
            <h3 className="text-2xl font-bold mb-4">Distribución General de Actividades</h3>
            <div className="w-64 h-64 mx-auto">
              <Pie data={generalChartData} ref={pieChartRef} />
            </div>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
            <h3 className="text-2xl font-bold mb-4">Comparación de Puestos de Trabajo</h3>
            <div className="h-64">
              <Bar 
                data={workstationComparisonData}
                options={{
                  indexAxis: 'y',
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: { stacked: true },
                    y: { stacked: true }
                  }
                }}
                ref={barChartRef}
              />
            </div>
          </div>

          <h3 className="text-2xl font-bold mb-4">Desglose por Puesto de Trabajo</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {workstationData.map((wd, index) => (
              <div key={index} className="bg-white shadow-lg rounded-lg p-6">
                <h4 className="text-xl font-bold mb-2">Puesto de Trabajo: {wd.name}</h4>
                <p><strong>Total de observaciones:</strong> {wd.total}</p>
                <p><strong>Actividades monitoreadas:</strong> {wd.monitored} ({((wd.monitored / wd.total) * 100).toFixed(2)}%)</p>
                <p><strong>Actividades no monitoreadas:</strong> {wd.nonMonitored} ({((wd.nonMonitored / wd.total) * 100).toFixed(2)}%)</p>
                <p><strong>Actividad media (tareas monitoreadas):</strong> {wd.averageActivity ? wd.averageActivity.toFixed(2) : 'N/A'}</p>
                <p><strong>Valoración del ritmo:</strong> {wd.activityStatus}</p>
                <div className="w-48 h-48 mx-auto mt-4">
                  <Pie 
                    data={{
                      labels: ['Monitoreadas', 'No Monitoreadas'],
                      datasets: [{
                        data: [wd.monitored, wd.nonMonitored],
                        backgroundColor: [wd.color, `${wd.color}80`],
                      }]
                    }}
                    ref={el => workstationChartRefs.current[wd.name] = el}
                  />
                </div>
                <h5 className="text-lg font-semibold mt-4 mb-2">Desglose de Tareas:</h5>
                <ul>
                  {wd.tasks.map((task, taskIndex) => (
                    <li key={taskIndex}>
                      <strong>{task.name}:</strong> {wd.taskCounts[task.id]} ({((wd.taskCounts[task.id] / wd.total) * 100).toFixed(2)}%)
                      - {task.monitored ? 'Monitoreada' : 'No Monitoreada'}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ),
      workstationData,
      totalObservations,
      totalMonitored,
      totalNonMonitored,
      generalChartData,
      workstationComparisonData
    };
  };

  const downloadDOCX = async () => {
    if (!study || !reportRef.current || !pieChartRef.current || !barChartRef.current) return;

    const reportData = generateReport();
    if (!reportData) return;

    try {
      const chartImages = {
        pieChartBase64: pieChartRef.current.toBase64Image().split(',')[1],
        barChartBase64: barChartRef.current.toBase64Image().split(',')[1],
        workstationChartImages: await Promise.all(
          reportData.workstationData.map(async (wd) => {
            const chartRef = workstationChartRefs.current[wd.name];
            return chartRef ? chartRef.toBase64Image().split(',')[1] : null;
          })
        ),
      };

      const doc = generateDocx(study, reportData, chartImages);
      const blob = await Packer.toBlob(doc);
      saveAs(blob, `informe-${study.idEstudio || 'ejecutivo'}.docx`);

    } catch (error) {
      console.error('Error generating DOCX:', error);
      alert('Hubo un error al generar el documento. Por favor, intente nuevamente.');
    }
  };

  if (loading) {
    return <div className="text-center py-8">Cargando...</div>;
  }

  if (!study) {
    return <div className="text-center py-8">Estudio no encontrado</div>;
  }

  const reportContent = generateReport();

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Informe Ejecutivo Detallado</h1>
      <div ref={reportRef}>
        {reportContent?.jsx}
      </div>
      <div className="text-center mt-8 space-x-4">
        <button
          onClick={downloadDOCX}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Descargar DOCX
        </button>
      </div>
    </div>
  );
};

export default ExecutiveReport;