import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import StudyConfig from './components/StudyConfig';
import ObservationPlan from './components/ObservationPlan';
import ObservationRecord from './components/ObservationRecord';
import ExecutiveReport from './components/ExecutiveReport';
import WorkstationsAndTasks from './components/WorkstationsAndTasks';
import PrivateRoute from './components/PrivateRoute';
import Header from './components/Header';

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="min-h-screen bg-gray-100 flex flex-col">
          <Header />
          <div className="flex-grow container mx-auto px-4 py-8">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="/study/new/config" element={<PrivateRoute><StudyConfig /></PrivateRoute>} />
              <Route path="/study/:studyId/config" element={<PrivateRoute><StudyConfig /></PrivateRoute>} />
              <Route path="/study/:studyId/workstations" element={<PrivateRoute><WorkstationsAndTasks /></PrivateRoute>} />
              <Route path="/study/:studyId/plan" element={<PrivateRoute><ObservationPlan /></PrivateRoute>} />
              <Route path="/study/:studyId/record" element={<PrivateRoute><ObservationRecord /></PrivateRoute>} />
              <Route path="/study/:studyId/report" element={<PrivateRoute><ExecutiveReport /></PrivateRoute>} />
            </Routes>
          </div>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;