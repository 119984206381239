import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { PlusCircle, Trash2, Edit2 } from 'lucide-react';

interface Task {
  id: string;
  name: string;
  monitored: boolean;
}

interface Workstation {
  id: string;
  name: string;
  tasks: Task[];
}

const WorkstationsAndTasks: React.FC = () => {
  const { studyId } = useParams<{ studyId: string }>();
  const navigate = useNavigate();
  const [workstations, setWorkstations] = useState<Workstation[]>([]);
  const [newWorkstationName, setNewWorkstationName] = useState('');
  const [newTaskName, setNewTaskName] = useState('');
  const [newTaskMonitored, setNewTaskMonitored] = useState(true);
  const [error, setError] = useState('');
  const [editingTask, setEditingTask] = useState<{ workstationId: string; taskId: string } | null>(null);
  const [editingTaskName, setEditingTaskName] = useState('');
  const [copyFromWorkstation, setCopyFromWorkstation] = useState('');
  const [studyTitle, setStudyTitle] = useState('');

  useEffect(() => {
    const fetchWorkstations = async () => {
      if (studyId) {
        const studyRef = doc(db, 'studies', studyId);
        const studySnap = await getDoc(studyRef);
        if (studySnap.exists()) {
          const studyData = studySnap.data();
          setWorkstations(studyData.workstations || []);
          setStudyTitle(studyData.idEstudio || '');
        }
      }
    };

    fetchWorkstations();
  }, [studyId]);

  const saveWorkstations = async (updatedWorkstations: Workstation[]) => {
    if (studyId) {
      const studyRef = doc(db, 'studies', studyId);
      await updateDoc(studyRef, { workstations: updatedWorkstations });
    }
  };

  const addWorkstation = () => {
    if (newWorkstationName.trim() === '') {
      setError('El nombre del puesto no puede estar vacío');
      return;
    }
    if (workstations.some(w => w.name.toLowerCase() === newWorkstationName.toLowerCase())) {
      setError('Ya existe un puesto con este nombre');
      return;
    }
    const newWorkstation: Workstation = {
      id: `workstation_${Date.now()}`,
      name: newWorkstationName,
      tasks: []
    };

    if (copyFromWorkstation) {
      const sourceWorkstation = workstations.find(w => w.id === copyFromWorkstation);
      if (sourceWorkstation) {
        newWorkstation.tasks = [...sourceWorkstation.tasks];
      }
    }

    const updatedWorkstations = [...workstations, newWorkstation];
    setWorkstations(updatedWorkstations);
    saveWorkstations(updatedWorkstations);
    setNewWorkstationName('');
    setCopyFromWorkstation('');
    setError('');
  };

  const deleteWorkstation = (workstationId: string) => {
    const updatedWorkstations = workstations.filter(w => w.id !== workstationId);
    setWorkstations(updatedWorkstations);
    saveWorkstations(updatedWorkstations);
  };

  const addTask = (workstationId: string) => {
    if (newTaskName.trim() === '') {
      setError('El nombre de la tarea no puede estar vacío');
      return;
    }
    const updatedWorkstations = workstations.map(w => {
      if (w.id === workstationId) {
        if (w.tasks.some(t => t.name.toLowerCase() === newTaskName.toLowerCase())) {
          setError('Ya existe una tarea con este nombre en este puesto');
          return w;
        }
        return {
          ...w,
          tasks: [...w.tasks, { id: `task_${Date.now()}`, name: newTaskName, monitored: newTaskMonitored }]
        };
      }
      return w;
    });
    setWorkstations(updatedWorkstations);
    saveWorkstations(updatedWorkstations);
    setNewTaskName('');
    setNewTaskMonitored(true);
    setError('');
  };

  const deleteTask = (workstationId: string, taskId: string) => {
    const updatedWorkstations = workstations.map(w => {
      if (w.id === workstationId) {
        return {
          ...w,
          tasks: w.tasks.filter(t => t.id !== taskId)
        };
      }
      return w;
    });
    setWorkstations(updatedWorkstations);
    saveWorkstations(updatedWorkstations);
  };

  const startEditingTask = (workstationId: string, taskId: string, taskName: string) => {
    setEditingTask({ workstationId, taskId });
    setEditingTaskName(taskName);
  };

  const saveEditingTask = () => {
    if (editingTask) {
      const updatedWorkstations = workstations.map(w => {
        if (w.id === editingTask.workstationId) {
          return {
            ...w,
            tasks: w.tasks.map(t => 
              t.id === editingTask.taskId ? { ...t, name: editingTaskName } : t
            )
          };
        }
        return w;
      });
      setWorkstations(updatedWorkstations);
      saveWorkstations(updatedWorkstations);
      setEditingTask(null);
      setEditingTaskName('');
    }
  };

  const handleContinue = () => {
    if (workstations.length === 0) {
      setError('Debe definir al menos un puesto de trabajo antes de continuar.');
      return;
    }
    if (workstations.some(w => w.tasks.length === 0)) {
      setError('Todos los puestos de trabajo deben tener al menos una tarea.');
      return;
    }
    navigate(`/study/${studyId}/plan`);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">
        Puestos de Trabajo y Tareas {studyTitle ? `(${studyTitle})` : ''}
      </h1>

      {error && <p className="text-red-500 mb-4">{error}</p>}

      <div className="mb-6 flex items-end space-x-4">
        <div className="flex-grow">
          <input
            type="text"
            value={newWorkstationName}
            onChange={(e) => setNewWorkstationName(e.target.value)}
            placeholder="Nombre del nuevo puesto"
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="flex-grow">
          <select
            value={copyFromWorkstation}
            onChange={(e) => setCopyFromWorkstation(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Copiar tareas desde...</option>
            {workstations.map((w) => (
              <option key={w.id} value={w.id}>{w.name}</option>
            ))}
          </select>
        </div>
        <button
          onClick={addWorkstation}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <PlusCircle className="mr-2" size={20} />
          Agregar Puesto
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {workstations.map((workstation) => (
          <div key={workstation.id} className="bg-white shadow-lg rounded-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">{workstation.name}</h2>
              <button
                onClick={() => deleteWorkstation(workstation.id)}
                className="text-red-500 hover:text-red-700"
              >
                <Trash2 size={20} />
              </button>
            </div>

            <div className="mb-4">
              <h3 className="text-lg font-medium text-gray-700 mb-2">Tareas Monitoreadas</h3>
              <ul className="space-y-2">
                {workstation.tasks.filter(task => task.monitored).map(task => (
                  <li key={task.id} className="flex justify-between items-center p-2 bg-green-100 rounded">
                    {editingTask && editingTask.workstationId === workstation.id && editingTask.taskId === task.id ? (
                      <input
                        type="text"
                        value={editingTaskName}
                        onChange={(e) => setEditingTaskName(e.target.value)}
                        onBlur={saveEditingTask}
                        onKeyPress={(e) => e.key === 'Enter' && saveEditingTask()}
                        className="flex-grow mr-2 p-1 border rounded"
                        autoFocus
                      />
                    ) : (
                      <span>{task.name}</span>
                    )}
                    <div>
                      <button
                        onClick={() => startEditingTask(workstation.id, task.id, task.name)}
                        className="text-blue-500 hover:text-blue-700 mr-2"
                      >
                        <Edit2 size={16} />
                      </button>
                      <button
                        onClick={() => deleteTask(workstation.id, task.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <Trash2 size={16} />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mb-4">
              <h3 className="text-lg font-medium text-gray-700 mb-2">Tareas No Monitoreadas</h3>
              <ul className="space-y-2">
                {workstation.tasks.filter(task => !task.monitored).map(task => (
                  <li key={task.id} className="flex justify-between items-center p-2 bg-red-100 rounded">
                    {editingTask && editingTask.workstationId === workstation.id && editingTask.taskId === task.id ? (
                      <input
                        type="text"
                        value={editingTaskName}
                        onChange={(e) => setEditingTaskName(e.target.value)}
                        onBlur={saveEditingTask}
                        onKeyPress={(e) => e.key === 'Enter' && saveEditingTask()}
                        className="flex-grow mr-2 p-1 border rounded"
                        autoFocus
                      />
                    ) : (
                      <span>{task.name}</span>
                    )}
                    <div>
                      <button
                        onClick={() => startEditingTask(workstation.id, task.id, task.name)}
                        className="text-blue-500 hover:text-blue-700 mr-2"
                      >
                        <Edit2 size={16} />
                      </button>
                      <button
                        onClick={() => deleteTask(workstation.id, task.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <Trash2 size={16} />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-4">
              <input
                type="text"
                value={newTaskName}
                onChange={(e) => setNewTaskName(e.target.value)}
                placeholder="Nombre de la tarea"
                className="w-full p-2 border rounded mb-2"
              />
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id={`monitoreada-${workstation.id}`}
                  checked={newTaskMonitored}
                  onChange={() => setNewTaskMonitored(!newTaskMonitored)}
                  className="mr-2"
                />
                <label htmlFor={`monitoreada-${workstation.id}`} className="text-sm font-medium text-gray-700">Monitoreada</label>
              </div>
              <button
                onClick={() => addTask(workstation.id)}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full"
              >
                <PlusCircle size={16} className="inline mr-2" /> Agregar Tarea
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8">
        <button
          onClick={handleContinue}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Continuar al Plan de Observaciones
        </button>
      </div>
    </div>
  );
};

export default WorkstationsAndTasks;