import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { format, isValid, parseISO } from 'date-fns';
import Modal from './Modal';
import { ChevronUp, ChevronDown } from 'lucide-react';

interface Task {
  id: string;
  name: string;
  monitored: boolean;
}

interface Workstation {
  id: string;
  name: string;
  tasks: Task[];
}

interface Observation {
  [workstationId: string]: {
    taskId: string;
    otherObservation?: string;
    activity?: number;
  };
}

const ObservationRecord: React.FC = () => {
  const { studyId } = useParams<{ studyId: string }>();
  const [study, setStudy] = useState<any>(null);
  const [observations, setObservations] = useState<{ [key: string]: Observation }>({});
  const [selectedObservation, setSelectedObservation] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [observationPlan, setObservationPlan] = useState<any[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [studyTitle, setStudyTitle] = useState('');

  useEffect(() => {
    const fetchStudy = async () => {
      if (studyId) {
        const studyRef = doc(db, 'studies', studyId);
        const studySnap = await getDoc(studyRef);
        if (studySnap.exists()) {
          const studyData = studySnap.data();
          setStudy(studyData);
          setObservations(studyData.observations || {});
          setObservationPlan(studyData.observationPlan || []);
          setStudyTitle(studyData.idEstudio || '');
        }
      }
    };

    fetchStudy();
  }, [studyId]);

  const calculateStatisticalParameters = (updatedObservations: { [key: string]: Observation }): StatisticalParameters => {
    const totalObservations = Object.keys(updatedObservations).length * study.workstations.length;
    const monitoredObservations = Object.values(updatedObservations).reduce((count, obs) => {
      return count + Object.entries(obs).filter(([workstationId, task]) => {
        const workstation = study.workstations.find((w: Workstation) => w.id === workstationId);
        const taskData = workstation?.tasks.find((t: Task) => t.id === task.taskId);
        return taskData?.monitored;
      }).length;
    }, 0);
    const p = monitoredObservations / totalObservations;
    const q = 1 - p;
    const z = study.statisticalParameters.confidenceLevel === '95' ? 1.96 : 2.58;
    const e = study.statisticalParameters.precision / 100;
    const n = Math.ceil((Math.pow(z, 2) * p * q) / Math.pow(e, 2));
    const h = Math.sqrt((p * q) / totalObservations) * z * 100;
    return {
      ...study.statisticalParameters,
      preliminaryObservations: totalObservations,
      activitiesOfInterest: monitoredObservations,
      p,
      q,
      n,
      h
    };
  };

  const formatObservationTime = (observationTime: any): string => {
    if (typeof observationTime === 'object' && observationTime.seconds) {
      const date = new Date(observationTime.seconds * 1000);
      return format(date, 'HH:mm');
    } else if (typeof observationTime === 'string') {
      const date = parseISO(observationTime);
      if (isValid(date)) {
        return format(date, 'HH:mm');
      }
    } else if (typeof observationTime === 'number') {
      const date = new Date(observationTime);
      return format(date, 'HH:mm');
    }
    return 'Invalid Time';
  };

  const handleObservationClick = (day: number, observationTime: any) => {
    setSelectedObservation({ day, observationTime });
    setShowModal(true);
  };

  const handleSaveObservation = async (observationData: Observation) => {
    const observationKey = `${selectedObservation.day}-${selectedObservation.observationTime}`;
    
    setIsSaving(true);
    setSaveError(null);

    try {
      const cleanedObservationData = Object.entries(observationData).reduce((acc, [key, value]) => {
        const cleanedValue = { ...value };
        if (cleanedValue.otherObservation === undefined) {
          delete cleanedValue.otherObservation;
        }
        acc[key] = cleanedValue;
        return acc;
      }, {} as Observation);

      const updatedObservationsClean = {
        ...observations,
        [observationKey]: cleanedObservationData,
      };

      const updatedStatisticalParameters = calculateStatisticalParameters(updatedObservationsClean);
      const studyRef = doc(db, 'studies', studyId);
      await updateDoc(studyRef, {
        observations: updatedObservationsClean,
        statisticalParameters: updatedStatisticalParameters,
      });

      setObservations(updatedObservationsClean);
      setShowModal(false);
    } catch (error) {
      console.error('Error al guardar la observación:', error);
      setSaveError('Hubo un error al guardar la observación. Por favor, intente nuevamente.');
    } finally {
      setIsSaving(false);
    }
  };

  if (!study) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">
        Registro de Observaciones {studyTitle ? `(${studyTitle})` : ''}
      </h1>

      <div className="space-y-8">
        {observationPlan.map((dayPlan) => (
          <div key={dayPlan.dia} className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Día {dayPlan.dia}</h3>
            </div>
            <div className="border-t border-gray-200">
              <ul className="divide-y divide-gray-200">
                {dayPlan.observaciones.map((observationTime: any, index: number) => {
                  const formattedTime = formatObservationTime(observationTime);
                  const observationKey = `${dayPlan.dia}-${observationTime}`;
                  const isObserved = observations[observationKey];
                  return (
                    <li key={index} className="px-4 py-4 sm:px-6">
                      <button
                        onClick={() => handleObservationClick(dayPlan.dia, observationTime)}
                        className={`w-full text-left py-2 px-4 rounded ${
                          isObserved ? 'bg-green-100 hover:bg-green-200' : 'bg-gray-100 hover:bg-gray-200'
                        }`}
                      >
                        {formattedTime}
                        {isObserved && <span className="ml-2 text-green-600">✓</span>}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ))}
      </div>

      {showModal && (
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title={`Observación - Día ${selectedObservation.day}, ${formatObservationTime(selectedObservation.observationTime)}`}
        >
          <ObservationForm
            workstations={study.workstations}
            onSave={handleSaveObservation}
            initialData={observations[`${selectedObservation.day}-${selectedObservation.observationTime}`]}
            isSaving={isSaving}
            saveError={saveError}
            activitySystem={study.activitySystem}
          />
        </Modal>
      )}
    </div>
  );
};

const ObservationForm = ({ 
  workstations, 
  onSave, 
  initialData, 
  isSaving, 
  saveError,
  activitySystem
}: { 
  workstations: Workstation[], 
  onSave: (data: Observation) => void, 
  initialData?: Observation,
  isSaving: boolean,
  saveError: string | null,
  activitySystem: 'centesimal' | 'bedaux'
}) => {
  const [formData, setFormData] = useState<Observation>(() => {
    if (initialData) {
      return initialData;
    }
    return workstations.reduce((acc, workstation) => {
      acc[workstation.id] = { 
        taskId: '',
        activity: activitySystem === 'centesimal' ? 100 : 60 
      };
      return acc;
    }, {} as Observation);
  });

  const handleTaskSelect = (workstationId: string, taskId: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [workstationId]: { 
        ...prevData[workstationId],
        taskId,
        otherObservation: taskId === 'other' ? prevData[workstationId]?.otherObservation || '' : undefined
      },
    }));
  };

  const handleOtherObservation = (workstationId: string, observation: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [workstationId]: { 
        ...prevData[workstationId],
        otherObservation: observation 
      },
    }));
  };

  const handleActivityChange = (workstationId: string, increment: boolean) => {
    setFormData((prevData) => ({
      ...prevData,
      [workstationId]: {
        ...prevData[workstationId],
        activity: Math.max(
          0,
          (prevData[workstationId]?.activity || (activitySystem === 'centesimal' ? 100 : 60)) + 
          (increment ? 5 : -5)
        )
      },
    }));
  };

  const getActivityColor = (activity: number) => {
    const normalActivity = activitySystem === 'centesimal' ? 100 : 60;
    const optimalActivity = activitySystem === 'centesimal' ? 133 : 80;
    
    if (activity < normalActivity) return 'text-red-600';
    if (activity > optimalActivity) return 'text-purple-600';
    if (activity === normalActivity) return 'text-green-600';
    return 'text-blue-600';
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {workstations.map((workstation: Workstation) => {
        // Sort tasks: monitored first, then non-monitored
        const sortedTasks = [...workstation.tasks].sort((a, b) => {
          if (a.monitored === b.monitored) return 0;
          return a.monitored ? -1 : 1;
        });

        return (
          <div key={workstation.id} className="space-y-2 p-4 bg-gray-50 rounded-lg">
            <h4 className="font-medium text-lg">{workstation.name}</h4>
            
            <div className="space-y-2">
              {sortedTasks.map((task: Task) => (
                <label key={task.id} className="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="radio"
                    name={`workstation-${workstation.id}`}
                    value={task.id}
                    checked={formData[workstation.id]?.taskId === task.id}
                    onChange={() => handleTaskSelect(workstation.id, task.id)}
                    className="form-radio"
                  />
                  <span className={task.monitored ? 'text-green-600' : 'text-red-600'}>
                    {task.name}
                  </span>
                </label>
              ))}
              
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="radio"
                  name={`workstation-${workstation.id}`}
                  value="other"
                  checked={formData[workstation.id]?.taskId === 'other'}
                  onChange={() => handleTaskSelect(workstation.id, 'other')}
                  className="form-radio"
                />
                <span>Otro</span>
              </label>
              
              {formData[workstation.id]?.taskId === 'other' && (
                <input
                  type="text"
                  value={formData[workstation.id]?.otherObservation || ''}
                  onChange={(e) => handleOtherObservation(workstation.id, e.target.value)}
                  placeholder="Especifique la observación"
                  className="mt-2 p-2 w-full border rounded"
                />
              )}

              <div className="mt-4 flex flex-col items-center">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Actividad
                </label>
                <div className="flex items-center space-x-2">
                  <button
                    type="button"
                    onClick={() => handleActivityChange(workstation.id, false)}
                    className="p-1 rounded hover:bg-gray-200"
                  >
                    <ChevronDown className="h-5 w-5" />
                  </button>
                  
                  <span className={`text-lg font-medium ${getActivityColor(formData[workstation.id]?.activity || (activitySystem === 'centesimal' ? 100 : 60))}`}>
                    {formData[workstation.id]?.activity || (activitySystem === 'centesimal' ? 100 : 60)}
                  </span>
                  
                  <button
                    type="button"
                    onClick={() => handleActivityChange(workstation.id, true)}
                    className="p-1 rounded hover:bg-gray-200"
                  >
                    <ChevronUp className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      
      {saveError && (
        <p className="text-red-500 text-sm">{saveError}</p>
      )}
      
      <button
        type="submit"
        disabled={isSaving}
        className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
          isSaving ? 'bg-indigo-400' : 'bg-indigo-600 hover:bg-indigo-700'
        } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      >
        {isSaving ? 'Guardando...' : 'Guardar Observación'}
      </button>
    </form>
  );
};

export default ObservationRecord;