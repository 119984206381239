import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { PlusCircle, Trash2, Calendar, Building2, FileText } from 'lucide-react';

const Dashboard = () => {
  const [studies, setStudies] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchStudies = async () => {
      if (currentUser) {
        const studiesRef = collection(db, 'studies');
        const q = query(studiesRef, where('userId', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);
        const studiesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setStudies(studiesData);
        setLoading(false);
      }
    };

    fetchStudies();
  }, [currentUser]);

  const handleDeleteStudy = async (studyId: string) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este estudio? Esta acción no se puede deshacer.')) {
      try {
        await deleteDoc(doc(db, 'studies', studyId));
        setStudies(studies.filter(study => study.id !== studyId));
      } catch (error) {
        console.error('Error al eliminar el estudio:', error);
      }
    }
  };

  const formatDate = (dateString: string) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('es-ES', options as any);
  };

  if (loading) {
    return <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-800">Cargando...</h1>
    </div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">Mis Estudios de Muestreo del Trabajo</h1>
      
      {studies.length > 0 && (
        <div className="mb-8">
          <Link
            to="/study/new/config"
            className="inline-flex items-center px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-medium rounded-lg transition-colors duration-300 ease-in-out shadow-md hover:shadow-lg"
          >
            <PlusCircle className="mr-2 h-5 w-5" />
            Nuevo Estudio
          </Link>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {studies.map((study) => (
          <div key={study.id} className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden">
            <div className="p-6">
              <h2 className="text-2xl font-bold text-gray-800 mb-3">{study.idEstudio}</h2>
              
              <div className="space-y-3">
                <div className="flex items-center text-gray-600">
                  <Building2 className="h-5 w-5 mr-2" />
                  <span>{study.empresa}</span>
                </div>
                
                <div className="flex items-center text-gray-600">
                  <Calendar className="h-5 w-5 mr-2" />
                  <span>{formatDate(study.fecha)}</span>
                </div>
              </div>

              <div className="mt-6 flex justify-between items-center">
                <Link
                  to={`/study/${study.id}/config`}
                  className="inline-flex items-center text-indigo-600 hover:text-indigo-800 font-medium transition-colors duration-300"
                >
                  <FileText className="h-5 w-5 mr-1" />
                  Ver detalles
                </Link>
                
                <button
                  onClick={() => handleDeleteStudy(study.id)}
                  className="text-red-500 hover:text-red-700 transition-colors duration-300"
                  title="Eliminar estudio"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {!loading && studies.length === 0 && (
        <div className="text-center py-12">
          <p className="text-gray-600 text-lg mb-4">No hay estudios creados aún.</p>
          <Link
            to="/study/new/config"
            className="inline-flex items-center px-6 py-3 bg-indigo-600 hover:bg-indigo-700 text-white font-medium rounded-lg transition-colors duration-300 ease-in-out"
          >
            <PlusCircle className="mr-2 h-5 w-5" />
            Crear mi primer estudio
          </Link>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
