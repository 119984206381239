import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDzXGge5Q3_gSQO9y9PnJ2WNRK9D-_sq74",
  authDomain: "muestreo-del-trabajo.firebaseapp.com",
  databaseURL: "https://muestreo-del-trabajo-default-rtdb.firebaseio.com",
  projectId: "muestreo-del-trabajo",
  storageBucket: "muestreo-del-trabajo.appspot.com",
  messagingSenderId: "384400987924",
  appId: "1:384400987924:web:366009da9c47928fa85401",
  measurementId: "G-8F9E10YYG5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };