import React, { useState, useEffect, useCallback, memo, useRef } from 'react';
import { calculateSampleSize, calculateError } from '../utils/statisticalCalculations';

interface StatisticalParametersProps {
  onParametersChange: (parameters: any) => void;
  initialParameters: any;
}

function useDebounce(callback: Function, delay: number) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return useCallback((...args: any[]) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);
}

const StatisticalParameters: React.FC<StatisticalParametersProps> = memo(({ 
  onParametersChange,
  initialParameters
}) => {
  const [confidenceLevel, setConfidenceLevel] = useState<string>('95');
  const [precision, setPrecision] = useState<number>(5);
  const [estimatedProportion, setEstimatedProportion] = useState<number>(50);
  const [preliminaryObservations, setPreliminaryObservations] = useState<number | null>(null);
  const [error, setError] = useState<string>('');
  const [averageObservationsPerWorkstation, setAverageObservationsPerWorkstation] = useState<number | null>(null);

  useEffect(() => {
    if (initialParameters?.statisticalParameters) {
      const { statisticalParameters } = initialParameters;
      
      setEstimatedProportion(statisticalParameters.p * 100);
      setPreliminaryObservations(statisticalParameters.preliminaryObservations || null);
      setConfidenceLevel(statisticalParameters.confidenceLevel || '95');
      setPrecision(statisticalParameters.precision || 5);

      // Calculate average observations per workstation
      if (statisticalParameters.preliminaryObservations && initialParameters.workstations?.length > 0) {
        const average = statisticalParameters.preliminaryObservations / initialParameters.workstations.length;
        setAverageObservationsPerWorkstation(average);
      }
    }
  }, [initialParameters]);

  const calculateParameters = useCallback(() => {
    const n = calculateSampleSize(confidenceLevel, precision, estimatedProportion);
    const h = preliminaryObservations 
      ? calculateError(confidenceLevel, estimatedProportion, preliminaryObservations)
      : calculateError(confidenceLevel, estimatedProportion, n);

    return {
      p: estimatedProportion / 100,
      q: 1 - (estimatedProportion / 100),
      n,
      h
    };
  }, [confidenceLevel, precision, estimatedProportion, preliminaryObservations]);

  const debouncedOnParametersChange = useDebounce((params: any) => {
    onParametersChange(params);
  }, 300);

  useEffect(() => {
    setError('');

    const { p, q, n, h } = calculateParameters();

    const newParameters = {
      confidenceLevel,
      precision,
      estimatedProportion,
      preliminaryObservations,
      p,
      q,
      n,
      h
    };

    debouncedOnParametersChange(newParameters);
  }, [confidenceLevel, precision, estimatedProportion, preliminaryObservations, calculateParameters, initialParameters, debouncedOnParametersChange]);

  const handleConfidenceLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setConfidenceLevel(e.target.value);
  };

  const handlePrecisionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrecision(Number(e.target.value));
  };

  const { p, q, n, h } = calculateParameters();

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-bold mb-6">Parámetros Estadísticos</h2>
      
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confidenceLevel">
          Nivel de Confianza
        </label>
        <select
          id="confidenceLevel"
          value={confidenceLevel}
          onChange={handleConfidenceLevelChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="95">95%</option>
          <option value="99">99%</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="precision">
          Precisión (%)
        </label>
        <input
          id="precision"
          type="number"
          value={precision}
          onChange={handlePrecisionChange}
          min="0"
          step="0.1"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="estimatedProportion">
          Proporción de Actividades Monitoreadas (%)
        </label>
        <input
          id="estimatedProportion"
          type="text"
          value={estimatedProportion.toFixed(2)}
          readOnly
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
        />
        <p className="text-sm text-gray-600 mt-1">
          Este valor se actualiza automáticamente con el porcentaje real de actividades monitoreadas durante el estudio.
        </p>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="preliminaryObservations">
          Promedio de Observaciones por Puesto
        </label>
        <input
          id="preliminaryObservations"
          type="text"
          value={averageObservationsPerWorkstation?.toFixed(2) || ''}
          readOnly
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
        />
        <p className="text-sm text-gray-600 mt-1">
          Este valor representa el promedio de observaciones realizadas por puesto de trabajo.
        </p>
      </div>

      {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}

      <h3 className="text-xl font-bold mb-4 mt-6">Resultados Calculados</h3>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="proportion">
            Proporción (p)
          </label>
          <input
            id="proportion"
            type="text"
            value={p.toFixed(4)}
            readOnly
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="complement">
            Complemento (q)
          </label>
          <input
            id="complement"
            type="text"
            value={q.toFixed(4)}
            readOnly
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sampleSize">
            Tamaño de muestra recomendado (n)
          </label>
          <input
            id="sampleSize"
            type="text"
            value={n.toFixed(0)}
            readOnly
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currentError">
            Error actual (h%)
          </label>
          <input
            id="currentError"
            type="text"
            value={h.toFixed(2) + '%'}
            readOnly
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
          />
        </div>
      </div>
    </div>
  );
});

export default StatisticalParameters;