import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { doc, getDoc, setDoc, addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import StatisticalParameters from './StatisticalParameters';

interface StatisticalParameters {
  confidenceLevel: string;
  precision: number;
  estimatedProportion: number;
  preliminaryObservations: number | null;
  p: number;
  q: number;
  n: number;
  h: number;
}

interface StudyData {
  empresa: string;
  fecha: string;
  tecnico: string;
  departamento: string;
  idEstudio: string;
  objetivos: string;
  notas?: string;
  statisticalParameters: StatisticalParameters;
  userId: string;
  createdAt: Date;
  activitySystem: 'centesimal' | 'bedaux';
  observations?: any;
  workstations?: any[];
}

const StudyConfig: React.FC = () => {
  const { studyId } = useParams<{ studyId: string }>();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm<StudyData>();
  const [loading, setLoading] = useState(true);
  const [statisticalParameters, setStatisticalParameters] = useState<StatisticalParameters | null>(null);
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [studyTitle, setStudyTitle] = useState<string>('');
  const [studyData, setStudyData] = useState<any>(null);

  const currentStudyId = watch('idEstudio');

  const fetchStudy = useCallback(async () => {
    if (studyId && studyId !== 'new') {
      try {
        const studyRef = doc(db, 'studies', studyId);
        const studySnap = await getDoc(studyRef);
        if (studySnap.exists()) {
          const fetchedStudyData = studySnap.data() as StudyData;
          setStudyData(fetchedStudyData);
          reset(fetchedStudyData);
          setStudyTitle(fetchedStudyData.idEstudio);
          
          const params = fetchedStudyData.statisticalParameters;
          setStatisticalParameters({
            ...params,
            preliminaryObservations: params.preliminaryObservations ?? null
          });
        }
      } catch (error) {
        console.error('Error al cargar el estudio:', error);
        setError('Hubo un problema al cargar los datos del estudio. Por favor, intente nuevamente.');
      }
    }
    setLoading(false);
  }, [studyId, reset]);

  useEffect(() => {
    fetchStudy();
  }, [fetchStudy]);

  useEffect(() => {
    if (currentStudyId && studyId === 'new') {
      setStudyTitle(currentStudyId);
    }
  }, [currentStudyId, studyId]);

  const handleParametersChange = useCallback((parameters: StatisticalParameters) => {
    setStatisticalParameters(prev => {
      if (JSON.stringify(prev) !== JSON.stringify(parameters)) {
        return parameters;
      }
      return prev;
    });
  }, []);

  const onSubmit: SubmitHandler<StudyData> = async (data) => {
    setIsSaving(true);
    setError('');
    try {
      if (!currentUser) {
        throw new Error('No hay usuario autenticado');
      }

      const studyDataToSave: StudyData = {
        ...data,
        userId: currentUser.uid,
        createdAt: new Date(),
        statisticalParameters: statisticalParameters || {},
        activitySystem: data.activitySystem || 'centesimal',
      };

      // Mantener las observaciones y workstations existentes si existen
      if (studyData?.observations) {
        studyDataToSave.observations = studyData.observations;
      }
      if (studyData?.workstations) {
        studyDataToSave.workstations = studyData.workstations;
      }

      let savedStudyId: string;
      if (!studyId || studyId === 'new') {
        const docRef = await addDoc(collection(db, 'studies'), studyDataToSave);
        savedStudyId = docRef.id;
      } else {
        await setDoc(doc(db, 'studies', studyId), studyDataToSave, { merge: true });
        savedStudyId = studyId;
      }
      navigate(`/study/${savedStudyId}/workstations`);
    } catch (error) {
      console.error('Error al guardar el estudio:', error);
      setError('Hubo un problema al guardar el estudio. Por favor, intente nuevamente.');
    } finally {
      setIsSaving(false);
    }
  };

  if (loading) {
    return <div className="text-center py-8">Cargando...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">
        Configuración del estudio {studyTitle ? `(${studyTitle})` : ''}
      </h1>
      
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <label htmlFor="empresa" className="block text-sm font-medium text-gray-700">Empresa</label>
          <input
            type="text"
            id="empresa"
            {...register('empresa', { required: 'Este campo es requerido' })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.empresa && <p className="mt-1 text-sm text-red-600">{errors.empresa.message}</p>}
        </div>

        <div>
          <label htmlFor="fecha" className="block text-sm font-medium text-gray-700">Fecha</label>
          <input
            type="date"
            id="fecha"
            {...register('fecha', { required: 'Este campo es requerido' })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.fecha && <p className="mt-1 text-sm text-red-600">{errors.fecha.message}</p>}
        </div>

        <div>
          <label htmlFor="tecnico" className="block text-sm font-medium text-gray-700">Técnico</label>
          <input
            type="text"
            id="tecnico"
            {...register('tecnico', { required: 'Este campo es requerido' })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.tecnico && <p className="mt-1 text-sm text-red-600">{errors.tecnico.message}</p>}
        </div>

        <div>
          <label htmlFor="departamento" className="block text-sm font-medium text-gray-700">Departamento</label>
          <input
            type="text"
            id="departamento"
            {...register('departamento', { required: 'Este campo es requerido' })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.departamento && <p className="mt-1 text-sm text-red-600">{errors.departamento.message}</p>}
        </div>

        <div>
          <label htmlFor="idEstudio" className="block text-sm font-medium text-gray-700">ID del Estudio</label>
          <input
            type="text"
            id="idEstudio"
            {...register('idEstudio', { required: 'Este campo es requerido' })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.idEstudio && <p className="mt-1 text-sm text-red-600">{errors.idEstudio.message}</p>}
        </div>

        <div>
          <label htmlFor="activitySystem" className="block text-sm font-medium text-gray-700">Sistema de Actividad</label>
          <select
            id="activitySystem"
            {...register('activitySystem', { required: 'Este campo es requerido' })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="centesimal">Centesimal (Normal: 100, Óptima: 133)</option>
            <option value="bedaux">Bedaux (Normal: 60, Óptima: 80)</option>
          </select>
          {errors.activitySystem && <p className="mt-1 text-sm text-red-600">{errors.activitySystem.message}</p>}
        </div>

        <div>
          <label htmlFor="objetivos" className="block text-sm font-medium text-gray-700">Objetivos del estudio</label>
          <textarea
            id="objetivos"
            {...register('objetivos', { required: 'Este campo es requerido' })}
            rows={3}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
          {errors.objetivos && <p className="mt-1 text-sm text-red-600">{errors.objetivos.message}</p>}
        </div>

        <div>
          <label htmlFor="notas" className="block text-sm font-medium text-gray-700">Notas adicionales</label>
          <textarea
            id="notas"
            {...register('notas')}
            rows={3}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
        </div>

        <StatisticalParameters 
          onParametersChange={handleParametersChange}
          initialParameters={studyData}
        />
        
        <div>
          <button
            type="submit"
            disabled={isSaving}
            className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
              isSaving ? 'bg-indigo-400' : 'bg-indigo-600 hover:bg-indigo-700'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            {isSaving ? 'Guardando...' : 'Guardar y Continuar'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default StudyConfig;