// Utility function for statistical calculations
export const calculateSampleSize = (confidenceLevel: string, precision: number, proportion: number) => {
  const p = proportion / 100;
  const q = 1 - p;
  const z = confidenceLevel === '95' ? 1.96 : 2.58;
  const e = precision / 100;
  return Math.ceil((Math.pow(z, 2) * p * q) / Math.pow(e, 2));
};

export const calculateError = (confidenceLevel: string, proportion: number, observations: number) => {
  const p = proportion / 100;
  const q = 1 - p;
  const z = confidenceLevel === '95' ? 1.96 : 2.58;
  return Math.sqrt((p * q) / observations) * z * 100;
};