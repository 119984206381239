import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Menu, X, LogOut } from 'lucide-react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const studyId = location.pathname.split('/')[2];
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navItems = [
    { path: '/', label: 'Inicio' },
    { path: `/study/${studyId}/config`, label: 'Configuración' },
    { path: `/study/${studyId}/workstations`, label: 'Puestos y Tareas' },
    { path: `/study/${studyId}/plan`, label: 'Plan de Observaciones' },
    { path: `/study/${studyId}/record`, label: 'Registro de Observaciones' },
    { path: `/study/${studyId}/report`, label: 'Informe Ejecutivo' },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  return (
    <header className="bg-gray-800 text-white">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <h1 className="text-xl font-bold">Muestreo del Trabajo</h1>
        <div className="flex items-center">
          {currentUser && (
            <button
              onClick={handleLogout}
              className="mr-4 flex items-center text-white bg-red-600 hover:bg-red-700 px-4 py-2 rounded-md transition duration-300 ease-in-out"
            >
              <LogOut size={20} className="mr-2" />
              Salir
            </button>
          )}
          {isMobile ? (
            <button onClick={toggleMenu} className="text-white">
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          ) : (
            <nav>
              <ul className="flex space-x-4">
                {navItems.map((item) => (
                  <li key={item.path}>
                    <Link
                      to={item.path}
                      className={`hover:text-gray-300 ${
                        location.pathname === item.path ? 'font-bold' : ''
                      }`}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>
      </div>
      {isMobile && (
        <div
          className={`fixed inset-0 z-50 transform ${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          } transition-transform duration-300 ease-in-out`}
        >
          <div className="bg-gray-800 h-full w-64 shadow-lg">
            <div className="p-4">
              <button onClick={toggleMenu} className="text-white float-right">
                <X size={24} />
              </button>
            </div>
            <nav className="mt-8">
              <ul className="space-y-2">
                {navItems.map((item) => (
                  <li key={item.path}>
                    <Link
                      to={item.path}
                      className={`block px-4 py-2 hover:bg-gray-700 ${
                        location.pathname === item.path ? 'bg-gray-700 font-bold' : ''
                      }`}
                      onClick={toggleMenu}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
                {currentUser && (
                  <li>
                    <button
                      onClick={() => {
                        handleLogout();
                        toggleMenu();
                      }}
                      className="block w-full text-left px-4 py-2 bg-red-600 hover:bg-red-700 text-white"
                    >
                      <LogOut size={20} className="inline mr-2" />
                      Cerrar sesión
                    </button>
                  </li>
                )}
              </ul>
            </nav>
          </div>
          <div
            className="bg-black bg-opacity-50 h-full w-full"
            onClick={toggleMenu}
          ></div>
        </div>
      )}
    </header>
  );
};

export default Header;